import { PickupStore } from "@/graphql/client";
import { sdk } from "@/plugins/graphql-provider";

/**
 * お受け取り可能店舗に関する機能を提供します.
 */
class PickupStoreService {
  /**
   * 初期状態の PickupStore
   */
  public get defaultPickupStore(): PickupStore {
    return {
      guid: "",
      name: "",
      address: "",
      createdAt: "",
      updatedAt: "",
    } as PickupStore;
  }

  /**
   * すべてのお受け取り可能店舗を取得して返します.
   * @returns すべてのお受け取り可能店舗
   */
  public async allPickupStores() {
    const response = await sdk.pickupStores();
    if (response?.pickupStores) {
      return response.pickupStores as PickupStore[];
    }
    return [] as PickupStore[];
  }

  /**
   * 指定したGUIDに一致するお受け取り可能店舗を取得して返します.
   * @param guid お受け取り可能店舗のGUID
   * @returns お受け取り可能店舗
   */
  public async getPickupStore(guid: string) {
    const response = await sdk.pickupStore({ pickupStoreGuid: guid });
    return response.pickupStore as PickupStore;
  }

  /**
   * お受け取り可能店舗を登録します.
   * @param input お受け取り可能店舗の登録情報
   * @returns 登録完了後のお受け取り可能店舗
   */
  public async createPickupStore(input: PickupStore) {
    try {
      const response = await sdk.createPickupStore({
        createPickupStoreInput: {
          name: input.name,
          address: input.address,
        },
      });

      if (response?.createPickupStore) {
        return response.createPickupStore;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * お受け取り可能店舗を更新します.
   * @param input お受け取り可能店舗の更新情報
   * @returns 更新完了後のお受け取り可能店舗
   */
  public async updatePickupStore(input: PickupStore) {
    try {
      const response = await sdk.updatePickupStore({
        updatePickupStoreInput: {
          guid: input.guid,
          name: input.name,
          address: input.address,
        },
      });

      if (response?.updatePickupStore) {
        return response.updatePickupStore;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * お受け取り可能店舗を削除します.
   * @param input お受け取り可能店舗の削除情報
   * @returns 削除したお受け取り可能店舗
   */
  public async deletePickupStore(input: PickupStore) {
    try {
      const response = await sdk.deletePickupStore({
        deletePickupStoreInput: {
          guid: input.guid,
        },
      });

      if (response?.deletePickupStore) {
        return response.deletePickupStore;
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default new PickupStoreService();
