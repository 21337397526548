









































import { PickupStore } from "@/graphql/client";
import { Component, Emit, Vue } from "vue-property-decorator";
import pickupStoreService from "@/service/pickupStoreService";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";

@Component({
  components: {
    ProgressLinear,
  },
})
export default class PickupStoreEditDialog extends Vue {
  //---------------------------
  // data
  //---------------------------
  isOpen = false;
  isProgressing = false;
  item: PickupStore = pickupStoreService.defaultPickupStore;
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  /**
   * ダイアログを表示します.
   */
  public open(item: PickupStore): void {
    this.item = item;

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 入力内容を保存します.
   */
  private deleteItem(): void {
    this.isProgressing = true;
    // delete
    pickupStoreService.deletePickupStore(this.item).then((result) => {
      this.isProgressing = false;
      this.notifySuccess(result as PickupStore);
    });
  }

  /**
   * 処理成功
   */
  @Emit("onSuccess")
  public notifySuccess(deleted: PickupStore): PickupStore {
    return deleted;
  }
}
