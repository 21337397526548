

























































import { Component, Ref, Vue } from "vue-property-decorator";
import { PickupStore } from "@/graphql/client";
import pickupStoreService from "@/service/pickupStoreService";
import { NotificationState } from "@/store/modules/notification";
import PickupStoreEditDialog from "@/components/organisms/pickup_store/PickupStoreEditDialog.vue";
import PickupStoreDeleteDialog from "@/components/organisms/pickup_store/PickupStoreDeleteDialog.vue";
import moment from "moment";

@Component({
  components: {
    PickupStoreEditDialog,
    PickupStoreDeleteDialog,
  },
})
export default class PickupStoreList extends Vue {
  @Ref() readonly editDialog!: PickupStoreEditDialog;
  @Ref() readonly deleteDialog!: PickupStoreDeleteDialog;
  //---------------------------
  // data
  //---------------------------
  items: PickupStore[] = [];
  readonly headers = [
    {
      text: "店舗名",
      value: "name",
      width: "20%",
    },
    {
      text: "住所",
      value: "address",
    },
    {
      text: "",
      value: "editAction",
      width: "30px",
      sortable: false,
    },
    {
      text: "",
      value: "deleteAction",
      width: "30px",
      sortable: false,
    },
  ];

  searchKeyword = "";
  loadingDataGrid = false;
  //---------------------------
  // mounted
  //---------------------------
  mounted(): void {
    this.fetchData();
  }

  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  async fetchData(): Promise<void> {
    this.loadingDataGrid = true;
    pickupStoreService.allPickupStores().then((items) => {
      this.items = items;
      this.loadingDataGrid = false;
    });
  }

  /**
   * 日付を「YYYY/MM/DD HH:mm」形式の文字列にして返します.
   */
  public formatDateTime(value: string): string {
    return value ? moment(value).format("YYYY/MM/DD HH:mm") : "";
  }
  /**
   * お受け取り可能店舗編集ダイアログ（新規追加）を表示します.
   */
  public openCreateDialog(): void {
    this.editDialog.open(
      Object.assign({}, pickupStoreService.defaultPickupStore)
    );
  }

  /**
   * お受け取り可能店舗編集ダイアログを表示します.
   */
  public openEditDialog(item: PickupStore): void {
    this.editDialog.open(Object.assign({}, item));
  }

  /**
   * お受け取り可能店舗編集ダイアログの更新成功時.
   */
  public onUpdateSuccess(updated: PickupStore): void {
    //一覧にも反映
    const index = this.items.findIndex((el) => {
      return el.guid === updated.guid;
    });
    if (index > -1) {
      this.items.splice(index, 1);
      this.items.splice(index, 0, updated);
    }

    this.editDialog.close();
    NotificationState.notifySuccess("お受け取り可能店舗を更新しました！");
  }

  /**
   * お受け取り可能店舗編集ダイアログの登録成功時.
   */
  public onCreateSuccess(created: PickupStore): void {
    //一覧にも追加
    this.items.unshift(created);

    this.editDialog.close();
    NotificationState.notifySuccess("お受け取り可能店舗を登録しました！");
  }

  /**
   * お受け取り可能店舗削除ダイアログを表示します.
   */
  public openDeleteDialog(item: PickupStore): void {
    this.deleteDialog.open(Object.assign({}, item));
  }

  /**
   * お受け取り可能店舗削除ダイアログの処理成功時.
   */
  public onDeleteSuccess(deleted: PickupStore): void {
    //一覧からも削除
    const newItems = this.items.filter((item) => item.guid !== deleted.guid);
    this.items = newItems;

    this.deleteDialog.close();
    NotificationState.notifySuccess("お受け取り可能店舗を削除しました！");
  }
}
