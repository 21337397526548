










import SettingSubPageTemplate from "@/components/templates/SettingSubPageTemplate.vue";
import PickupStoreList from "@/components/organisms/pickup_store/PickupStoreList.vue";

export default {
  components: {
    SettingSubPageTemplate,
    PickupStoreList,
  },
};
