



















































































import { PickupStore } from "@/graphql/client";
import { Component, Ref, Emit, Vue } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import pickupStoreService from "@/service/pickupStoreService";
import { required } from "vuelidate/lib/validators";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";
import moment from "moment";

@Component({
  components: {
    ProgressLinear,
  },
})
export default class PickupStoreEditDialog extends Vue {
  @Ref() readonly editForm!: HTMLFormElement;
  @Validations()
  validations = {
    item: {
      //名称
      name: { required },
      //住所
      address: { required },
    },
  };
  //---------------------------
  // data
  //---------------------------
  isOpen = false;
  isProgressing = false;
  item: PickupStore = pickupStoreService.defaultPickupStore;
  valid = false;
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  /**
   * 「YYY-MM-DD HH:mm:ss」形式の登録日時を取得
   */
  get formattedCreatedAt(): string {
    return this.item.createdAt
      ? moment(this.item.createdAt).format("YYYY-MM-DD HH:mm:ss")
      : "";
  }
  /**
   * 「YYY-MM-DD HH:mm:ss」形式の更新日時を取得
   */
  get formattedUpdatedAt(): string {
    return this.item.updatedAt
      ? moment(this.item.updatedAt).format("YYYY-MM-DD HH:mm:ss")
      : "";
  }

  /**
   * 店舗名の入力エラーメッセージを返します.
   */
  get nameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.name?.$dirty) return errors;
    !this.$v.item.name?.required && errors.push("店舗名を入力してください.");
    return errors;
  }
  /**
   * 住所の入力エラーメッセージを返します.
   */
  get addressErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.address?.$dirty) return errors;
    !this.$v.item.address?.required && errors.push("住所を入力してください.");
    return errors;
  }
  //---------------------------
  // methods
  //---------------------------
  async fetchData(item: PickupStore): Promise<void> {
    this.item = pickupStoreService.defaultPickupStore;
    if (item.guid) {
      const store = await pickupStoreService.getPickupStore(item.guid);
      if (store) {
        this.item = store;
      }
    }
  }
  /**
   * ダイアログを表示します.
   */
  public async open(item: PickupStore): Promise<void> {
    this.fetchData(item);

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.$v.$reset();
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 入力内容を保存します.
   */
  save(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.isProgressing = true;
      if (this.item.guid) {
        //update
        pickupStoreService
          .updatePickupStore(this.item)
          .then((result) => {
            this.isProgressing = false;
            this.notifyUpdateSuccess(result as PickupStore);
          })
          .catch((e) => {
            console.log(e);
            this.notifyError("お受け取り可能店舗の更新ができませんでした.");
          });
      } else {
        //create
        pickupStoreService
          .createPickupStore(this.item)
          .then((result) => {
            this.isProgressing = false;
            this.notifyCreateSuccess(result as PickupStore);
          })
          .catch((e) => {
            console.log(e);
            this.notifyError("お受け取り可能店舗の登録ができませんでした.");
          });
      }
    }
  }

  /**
   * 登録成功
   */
  @Emit("onCreateSuccess")
  public notifyCreateSuccess(created: PickupStore): PickupStore {
    return created;
  }
  /**
   * 更新成功
   */
  @Emit("onUpdateSuccess")
  public notifyUpdateSuccess(updated: PickupStore): PickupStore {
    return updated;
  }
  /**
   * 失敗
   */
  @Emit("onError")
  public notifyError(message: string): string {
    return message;
  }
}
